import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import axios from "axios";

const BlogList = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const IMG_URL = process.env.REACT_APP_BASE_API_PATH
  const [blog, setBlog] = useState([]);  // Initialize as an array

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${API_URL}/blog/list/`);
        setBlog(response.data);  // Assume response.data is the array of blogs
      } catch (error) {
        console.error('Error fetching the blogs:', error);
      }
    };
    fetchBlogs();
  }, []);

  console.log(blog, "blog data !!");

  return (
    <>
      <Header />
      <main id="content" className="mt-100">
        <section className="py-13 bg-gray-01">
          <div className="container">
            <h2 className="fs-30 lh-16 mb-10 text-dark font-weight-600 text-center">
              Interesting articles updated daily
            </h2>
            <div className="row">
              {blog && blog.length > 0 ? (
                blog.map((item, index) => (
                  <div className="col-md-4 mb-6" key={index}>
                    <div className="card border-0 shadow-xxs-3">
                      <div className="position-relative d-flex align-items-end card-img-top">
                        <a href={`/blog-single/${item.id}`} className="hover-shine">
                          <img
                            src={item.image_url ? `${IMG_URL}${item.image_url}` : "images/default.jpg"}  // Use item.image_url or a default image
                            alt={item.title}
                            style={{ width: "200px", height:"250px;"}}
                          />
                        </a>
                      </div>
                      <div className="card-body px-5 pt-3 pb-5">
                        <p className="mb-1 fs-13">{new Date(item.create_at).toDateString()}</p>
                        <h3 className="fs-18 text-heading lh-194 mb-1">
                          <a href={`/blog-single/${item.id}`} className="text-heading">
                            {item.title}
                          </a>
                        </h3>
                        <p className="mb-3">{item.short}</p>
                        <a
                          className="text-blue hover-saffron-text font-weight-500"
                          href={`/blog-single/${item.id}`}
                        >
                          Learn more
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading blogs...</p>
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default BlogList;
