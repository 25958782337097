import React, { useState } from 'react'
import { Link, useNavigate ,redirect} from 'react-router-dom'
import AuthService from './AuthService';
import { isExpired, decodeToken } from "react-jwt";
import axios from 'axios';
import Loader from '../../Loader';
import { toast } from "react-toastify";


function Login() {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL
  const [loading, setLoading] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // Password Visible
  const [visible, setVisible] = useState(false);
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };
  const handleLogin = async () => {
    setLoading(true)
    try {
      const token = await AuthService.login(email, password);
     
      const myDecodedToken = decodeToken(token?.access);
      const isMyTokenExpired = isExpired(token?.refresh);
     
      const currentUserId = myDecodedToken?.user_id;

      localStorage.setItem('is_authenticated', isMyTokenExpired);
      localStorage.setItem('currentUser_id', currentUserId);
      setLoading(true)
      try {
        const response = await axios.post(`${API_URL}/auth/jwt/verify/`, {
          token: token?.access,
        });


        if (response.status === 200) {
          setLoading(false)
          navigate("/dashboard")
        } else {
          setLoading(false)
          navigate("/login")
        }
      } catch (error) {
        setLoading(false)
        // alert(error)
        //   navigate("/login")
        //   if (error.response.data.password) {
        //     toast.error(`${error.response.data.password}`)
        //   } else if (error.response.data.email) {
        //     toast.error(`${error.response.data.email}`)
        //   }
      }
      // Save the token and user data in local storage or a state management solution.
    } catch (error) {
      setLoading(false)
     
      toast.error(error?.response?.data.detail);
    }
  };

  return (<>
    {loading ? <Loader /> : null}
    <div className="form-body">
      <div className="website-logo">
        <Link to="/">
          <div className="logo">
            <img
              className="logo-size"
              src="../../images/dpf-logo-ae.png"
              style={{ width: "150px" }}
            />
          </div>
        </Link>
      </div>
      <div className="img-holder">
        <div
          className="bg"
          style={{ backgroundImage: "url(images/skyline-2.png)" }}
        ></div>
      </div>
      <div className="form-holder">
        <div className="form-content fixed-in-mobile">
          <div className="form-items">
            <h3 className="ti-fs">
              Secured Login to{" "}
              <Link  to ="/">
                <span className="pl-name">AIM Time Business</span>
              </Link>
            </h3>
            <div className="form-group mb-4">
              {/* username input */}
              <label htmlFor="username" className="sr-only">
                Email
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend ">
                  <span
                    className="input-group-text bg-gray-01 border-0 text-muted fs-18"
                    id="inputGroup-sizing-lg"
                  >
                    <i className="far fa-user"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control border-0 shadow-none fs-13"
                  id="email"
                  name="email"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group mb-4">
              {/* password input */}
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="input-group input-group-lg">
                <div className="input-group-prepend ">
                  <span className="input-group-text bg-gray-01 border-0 text-muted fs-18">
                    <i className="far fa-lock"></i>
                  </span>
                </div>
                <input
                  type={visible ? "text" : "password"}
                  className="form-control border-0 shadow-none fs-13"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                   onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
                />
                <div className="input-group-append">
                  <span className="input-group-text bg-gray-01 border-0 text-body fs-18">
                    <i
                      className="far fa-eye-slash"
                      onClick={() => setVisible(!visible)}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex mb-4">
              <Link
                to="/forgetpassword"
                className="d-inline-block ml-auto text-orange fs-15"
              >
                Forgot Password?
              </Link>
            </div>
            <div className="Submit-button-section-login">
              <button
                onClick={handleLogin}
                className="btn text-white bg-blue hover-saffron btn-lg btn-block"
              >
                Log in
              </button>
            </div>
            <div className="w-100 mt-5">
              <div style={{ textAlign: "-webkit-center" }}>
                {/* <Googlelogin /> */}
              </div>
            </div>
            <p className="lg-bo">
              Don't have an account?{" "}
              <Link to="/signup" className="text-blue hover-saffron-text">
                <u>Sign up</u>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default Login