import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import axios from "axios";
import { ApiUrl } from "../../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
// import contactImage from '../../../src/images/conatct-header.png'
// import BackLineImage from "../../../src/images/bg_circle.png";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";



const ContactUs = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [firstname,SetFirstName] = useState();
  const [lastname, SetLastName] = useState();
  const [phonenumber, SetPhoneNumber] = useState();
  const [email, SetEmail] = useState();
  const [message, SetMessage] = useState();

const ValidateFields = () => {
  if (!firstname || firstname?.trim() === "") {
    toast.error("Please Enter First Name")
    return false;
  }
  if(!email || email?.trim() === "") {
    toast.error("Please Enter Email")
    return false;
  }
  return true;
}

const QueryMail = () => {
  if (!ValidateFields()) return;

  const data = {
    first_name: firstname,
    last_name: lastname,
    phone: phonenumber,
    email: email,
    message: message
  };

  axios.post(`${API_URL}/main/contact/`, data)
    .then(response => {
      SetFirstName("");
      SetLastName("");
      SetPhoneNumber("");
      SetEmail("");
      SetMessage("");
      if (response.status === 201) {
        toast.success("Your Query Submitted Successfully");
      }
    })
    .catch(error => {
      console.error("API Error: ", error);
      toast.error("Submission Unsuccessful");
    });
};


 return (
    <>
      <Header />
      <main id="content mt-100">

        <section class="py-14 py-lg-15 page-title op-7">
          
        {/* style={{ backgroundImage: `url(${contactImage})`, backgroundSize: "cover", backgroundPosition: "center", marginTop: "114px" }} */}
          <div class="container">
           
          </div>
        </section>
        <section> 
          <div class="container">
            <div class="card border-0 mt-n13 z-index-3 pb-8 pt-10">
              <div class="card-body p-0">
                <h2 class="text-heading mb-2 fs-22 fs-md-32 text-center lh-16">We're always eager to hear from
                  you!</h2>
                <p class="text-center  mb-8 fs-20">
                  Never miss have any issues or regarding to make more portal features<br /><span ><img className="mt-5" style={{ width: "100px" }} src="images/aimtimebusiness.png" /> </span>
                </p>



                <form class="mxw-751 px-md-5">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group position-add-validate-symbol">
                        <input type="text" placeholder="First Name" onChange={(e) => SetFirstName(e?.target?.value)} value={firstname}
                       
                          class="form-control form-control-lg border-0" name="first-name" />
                          <span className="asterisk_input"></span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input type="text" placeholder="Last Name" name="last-name" onChange = {(e) => SetLastName(e?.target?.value)} value={lastname}
                          class="form-control form-control-lg border-0" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group position-add-validate-symbol">
                        <input placeholder="Your Email"
                          class="form-control form-control-lg border-0" onChange = {(e) => SetEmail(e?.target?.value)} value={email}
                          type="email" name="email" />
                          <span className="asterisk_input"></span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <CurrencyFormat  format="+91 ### ###-####" mask="_" placeholder="Your Phone" name="phone" onChange = {(e) => SetPhoneNumber(e?.target?.value)} value={phonenumber}
                          class="form-control form-control-lg border-0" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-6">
                    <textarea class="form-control border-0" placeholder="Message" name="message" onChange ={(e) => SetMessage(e?.target?.value)} value={message}
                      rows="5"></textarea>
                  </div>
                  <div class="text-center">
                    <button type="button" class="btn btn-lg bg-blue text-white hover-saffron px-9" onClick={QueryMail}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
            </div></section>
            <section className=" position-relative bg-img-cover-center"
          > 
          {/* style={{ backgroundImage: `url(${BackLineImage})` }} */}
          <div className="container ">
            <div class="row text-center">

            <center>
              <div class="col-md-4  py-8">
                <div class="card border-0  py-4">
                  <div class="">
                    <span class="text-primary fs-40 lh-1 d-inline-block">
                      <img src="images/buyers.png" />
                    </span>
                    <div class="media-body">
                      <h4 class="fs-22 lh-15 mb-0 text-heading">India</h4>
                    </div>


                  </div>
                  <div class="card-body px-0 pt-3 pb-0">
                    <p class="card-text mb-0">
                     Bihar
                    </p>
                    
                  </div>
                </div>
              </div>
            </center>
            </div>
          </div>
        </section>
        <section className="">
          <div className="">
          <iframe className="footer-newsletter" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.3303833182376!2d-79.69736643370501!3d43.724463095871045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c354cf29489%3A0x89e936be5b5481b7!2s10-8550%20Torbram%20Rd%20%23288%2C%20Brampton%2C%20ON%20L6T%200H7%2C%20Canada!5e0!3m2!1sen!2sin!4v1669028135458!5m2!1sen!2sin"   height="400px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
export default ContactUs;