
import React, { useState, useEffect, useRef } from "react";

function SearchLocationInput(props) {
  

  const ref = useRef();
  return (

      <main id="content">
        <section className="container mt-1">
          {/* Row for the button group */}
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="d-flex justify-content-between align-items-center p-3">
                
                {/* Investment Button */}
                <a 
                  type="button"  href="mlm/lottery"
                  className="btn btn-lg bg-blue light-hover text-white w-100 mr-2 rounded-lg font-weight-bold text-uppercase"
                  style={{ flex: 1 }}
                >
                  Donate to Crowd Funding
                </a>
                
                {/* Donation Button */}
                <a href="mlm/donation"
                  type="button" 
                  className="btn  btn-lg light-hover text-white bg-saffron w-100 rounded-lg font-weight-bold text-uppercase"
                  style={{ flex: 1 }}
                >
                  Donate to Company
                </a>
              </div>
            </div>
          </div>
      
        </section>
      </main>
   
  );
}

export default SearchLocationInput;