import React, { useEffect, useState } from "react";
import { BsInstagram,BsLinkedin, BsSkype, BsTwitter } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';



const Footer = ({searchAddress}) => {

    return (
        <>
            <footer className="bg-dark pt-8 pb-6 footer text-muted">
                <div className="container container-xxl">
                    <div className="mb-6 mb-md-0 footer-icon">
                            
                            <div className="lh-26 font-weight-500">
                                
                            </div>
                        </div>
                    <div className="row footer-grid">
                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Our Services</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a className="text-muted lh-26 font-weight-500 hover-white">Lottery Donation</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Membership</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Non Working</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Level</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Donation(partner)</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Quick links</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/about-us" className="text-muted lh-26 font-weight-500 hover-white">About</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/contact-us" className="text-muted lh-26 font-weight-500 hover-white">Contact</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/" className="text-muted lh-26 font-weight-500 hover-white">Privacy Policy</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a href="/blog-list" className="text-muted lh-26 hover-white font-weight-500">Blog</a>
                                </li>
                              
                            </ul>
                        </div>

                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                            <h4 className="text-white fs-16 my-4 font-weight-500">Our Projects</h4>
                            <ul className="list-group list-group-flush list-group-no-border">
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >New Development</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a  className="text-muted lh-26 font-weight-500 hover-white" >Featured</a>
                                </li>
                                <li className="list-group-item bg-transparent p-0">
                                    <a className="text-muted lh-26 font-weight-500 hover-white"  >Pre-Launch</a>
                                </li>
                            
                                
                            </ul>
                        </div>
                        <div className="col-md-3 col-lg-3 mb-6 mb-md-0 footer-subscribe">
                       
                        
                              <h4 className="text-white fs-16 my-4 font-weight-500">Get in touch for upcoming projects</h4>
                            <p className="font-weight-500 text-muted lh-184">Never miss a commercial project deal. Maximize your return with Dollar Per Foot.</p>
                       
                            
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsTwitter />
                                    </a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <FaFacebookF /></a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsInstagram /></a>
                                </li>
                                <li className="list-inline-item mr-0">
                                    <a href="#" className="text-white opacity-3 fs-25 px-4 opacity-hover-10">
                                        <BsLinkedin /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-0 mt-md-10 row">
                        <ul className="list-inline mb-0 col-md-6 mr-auto">
                            <li className="list-inline-item mr-6">
                                <a href="#" className="text-muted lh-26 font-weight-500 hover-white">Terms of Use</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#" className="text-muted lh-26 font-weight-500 hover-white">Privacy Policy</a>
                            </li>
                        </ul>
                        <p className="col-md-auto mb-0 text-muted">
                            © 2022 DollarPerFoot. All Rights Reserved
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;