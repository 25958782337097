import React from 'react'
import LotteryComponent from '../Components/LotteryPage/LotteryComponent'

function Lottery() {
  return (
    <>
    
    <LotteryComponent />
    </>
  )
}

export default Lottery