import axios from "axios";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../interceptor/axiosInstance";
import "./LevelPage.css"; // Import custom CSS for additional styling
import { Modal } from "react-bootstrap";

const MyLevelComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [selectedCard, setSelectedCard] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nonWorkingRewards, setNonWorkingRewards] = useState([]);
  const [userReward, setUserReward] = useState(null);

  // State for branches and payment pending
  const [pendingBranches, setPendingBranches] = useState([]);
  const [paymentPending, setPaymentPending] = useState([]);

  useEffect(() => {
    const fetchNonWorkingRewards = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/account/member/ship/reward/`
        );
        setNonWorkingRewards(response.data);
      } catch (error) {
        console.error("Error fetching non-working rewards:", error);
      }
    };

    const fetchUserReward = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_URL}/account/get/reward/match/`
        );
        setUserReward(response.data);

        // Set pending branch data
        setPendingBranches(response.data.pending_branch);
        
        // Set payment pending data
        setPaymentPending(response.data.payment_pending);
      } catch (error) {
        console.error("Error fetching user reward:", error);
      }
    };

    fetchNonWorkingRewards();
    fetchUserReward();
  }, []);

  const handleCardClick = (index) => {
    const value = nonWorkingRewards[index]?.reward_price || 0;
    setSelectedCard(index);
    setInvestmentAmount(value);
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setSelectedCard(null); // Reset selected card
    setInvestmentAmount(null); // Reset investment amount
  };

  return (
    <div className="container my-level-container">
      <header className="text-center mb-4">
        <h1 className="display-4">Your Level</h1>
      </header>

      <div className="row justify-content-center my-4 position-relative">
        {nonWorkingRewards.map((reward, index) => {
          const isMatched = userReward && userReward.data.member_ship_reward.includes(reward.id);
          const isPassed = reward.passed;

          return (
            <div
              key={reward.id}
              className={`col-12 col-md-2 m-2 text-center rounded p-4 ${isMatched ? "bg-success" : isPassed ? "bg-danger" : "bg-danger"}`} // Red background
              onClick={() => handleCardClick(index)}
              style={{
                cursor: "pointer",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                transform: selectedCard?.id === reward.id ? "scale(1.1)" : "scale(1)",
                zIndex: selectedCard?.id === reward.id ? 1 : 0,
                position: selectedCard?.id === reward.id ? "relative" : "static",
              }}
            >
              <div className="icon-container mb-2">
                <img src={reward.img} alt="Reward Icon" className="reward-icon" />
              </div>
              <div className="reward-info text-white">
                <h2 className="h5">{reward.arrange}</h2>
                <h3 className="h6">{reward.plan_name}</h3>
                <h4>Rs. {reward.reward_price}</h4>
              </div>
            </div>
          );
        })}
      </div>

      <section className="pending-branch-section my-4">
        <h2>Pending Branches</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Parent User</th>
              <th>Branch Name</th>
            </tr>
          </thead>
          <tbody>
            {pendingBranches.map((branch, index) => (
              <tr key={index}>
                <td>{branch.parent_user}</td>
                <td>{branch.branch_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Payment Pending Table */}
      <section className="payment-pending-section my-4">
        <h2>Payment Pending</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Parent User</th>
              <th>Pending User</th>
              <th>Joined Date</th>
              <th>Payment Pending</th>
              <th>Payment Status</th>
              <th>Branch Name</th>
            </tr>
          </thead>
          <tbody>
            {paymentPending.map((payment, index) => (
              <tr key={index}>
                <td>{payment.parent_user}</td>
                <td>{payment.pending_user}</td>
                <td>{new Date(payment.joined_date).toLocaleDateString()}</td>
                <td>{payment.payment_pending ? "Yes" : "No"}</td>
                <td>{payment.payment_status ? "Completed" : "Pending"}</td>
                <td>{payment.branch_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Modal for showing selected card details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {nonWorkingRewards[selectedCard]?.plan_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCard !== null && (
            <>
              <img
                src={nonWorkingRewards[selectedCard].img}
                alt="Reward Icon"
                className="reward-icon mb-2"
              />
              <h2>{nonWorkingRewards[selectedCard].arrange}</h2>
              <h4>Rs. {nonWorkingRewards[selectedCard].reward_price}</h4>
              {nonWorkingRewards[selectedCard].details && (
                <div
                  className="details-section text-secondary"
                  dangerouslySetInnerHTML={{
                    __html: nonWorkingRewards[selectedCard].details,
                  }}
                />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyLevelComponent;
