import React, { useEffect, useState } from "react";
import Header from "../../Components/header/header";
import Footer from "../../Components/footer/footer";
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from "react-animation-on-scroll";
// import BackLineImage from "../../../src/images/bg_circle.png";
import { ApiUrl } from "../../utils/common";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";


function LearningComponents() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  // Fetch images from the API
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/blog/business/plan/image/`);
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
    fetchImages();
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
    <div className="container my-4">
      <br />
      <br />

      <br />


      <h1 className="text-center text-red mb-4">Business Plan List</h1>
      <hr />
      <div className="row">
        {images.map((imageData) => (
          <div className="col-md-4 col-sm-6 mb-4" key={imageData.id}>
            <div className="card text-center">
              <img
                src={imageData.image}
                alt={imageData.plan_name}
                className="card-img-top img-thumbnail"
                style={{ cursor: 'pointer', height: '150px', objectFit: 'cover' }}
                onClick={() => openModal(imageData.image)}
              />
              <div className="card-body">
                <p className="card-text">{imageData.plan_name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for full-size image popup */}
      {selectedImage && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Full-size Image</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="modal-body text-center">
                <img src={selectedImage} alt="Full-size view" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default LearningComponents